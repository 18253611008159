/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { EpisodeConsumer } from "../components/context"
import SEO from "../components/seo"
import Header from "../components/header"
import Aside from "../components/aside"
import StarRating from "../components/starRating"
import { SkipNavContent } from "@reach/skip-nav"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function EpisodeTemplate({ data: { contentfulEpisode } }) {
  const image = undefined //episode.image.fluid
  const markdown = undefined // markdownRemark && markdownRemark

  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>

  const options = {
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      // eslint-disable-next-line react/display-name
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    },
  }

  return (
    <EpisodeConsumer>
      {(context) => (
        <div>
          <SEO
            title={contentfulEpisode.name && contentfulEpisode.name}
            image={image && image}
            description={
              contentfulEpisode.shortDescription &&
              contentfulEpisode.shortDescription
            }
          />
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
            }}
          >
            <SkipNavContent sx={{ maxWidth: ["100%", 710] }}>
              <Header
                context={context}
                episode={contentfulEpisode}
                image={image}
              />
              <article>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {contentfulEpisode.johnRating && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "15px",
                        marginTop: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: "0.875em",
                        }}
                        sx={{ color: "primary" }}
                      >
                        Matt:
                        <span>
                          <StarRating
                            ratingNumber={contentfulEpisode.mattRating}
                          />
                        </span>
                      </div>

                      <div
                        style={{ fontSize: "0.875em" }}
                        sx={{ color: "primary" }}
                      >
                        John:
                        <span>
                          <StarRating
                            ratingNumber={contentfulEpisode.johnRating}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {contentfulEpisode.showNotes && (
                  <div style={{ marginTop: "10px" }}>
                    <div>
                      {documentToReactComponents(
                        contentfulEpisode.showNotes
                          .childContentfulShowNotesTextShowNotesRichTextNode
                          .json,
                        options
                      )}
                    </div>
                  </div>
                )}
              </article>
            </SkipNavContent>
            <Aside episode={contentfulEpisode} />
          </div>
        </div>
      )}
    </EpisodeConsumer>
  )
}

export default EpisodeTemplate

export const episodeQuery = graphql`
  query EpisodeQuery($id: String!) {
    contentfulEpisode(id: { eq: $id }) {
      id
      name
      showNotes {
        childContentfulShowNotesTextShowNotesRichTextNode {
          json
        }
      }
      heroImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      image {
        fluid(maxWidth: 720) {
          ...GatsbyContentfulFluid
        }
      }
      guests {
        name
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        fields {
          slug
        }
      }
      anchorEmbedLink {
        anchorEmbedLink
      }
      johnRating
      mattRating
      createdAt
      publicationDate
      shortDescription
      season
      episodeNumber
      fields {
        slug
      }
    }
  }
`
