import React from "react"
import { motion } from "framer-motion"

const StarRating = ({ ...props }) => {
  const stars = []
  for (let index = 1; index <= 10; index++) {
    const faIcon = index <= props.ratingNumber ? "fas fa-star" : "far fa-star"
    stars.push(
      <>
        {index <= props.ratingNumber && (
          <motion.i
            whileHover={{ rotate: 360, transition: { duration: 0.7 } }}
            className={faIcon}
            style={{ color: "#e6762c" }}
          />
        )}
        {index > props.ratingNumber && (
          <i className={faIcon} style={{ color: "#e6762c" }} />
        )}
      </>
    )
  }
  return <span style={{ fontSize: "0.775em", marginLeft: "5px" }}>{stars}</span>
}

export default StarRating
