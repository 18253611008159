/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import config from "../lib/config"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Link from "./link"
import itunesIcon from "../images/apple.svg"
import spotifyImage from "../images/spotify.png"
import googleImage from "../images/google.svg"
import anchorImage from "../images/anchor.png"
import logo from "../../static/cinemamen.png"
import React from "react"

const PodcastProvider = styled(Link)(
  css({
    mb: 5,
    display: "flex",
    alignItems: "center",
    img: { m: 0, mr: 3 },
  })
)

function Aside({ episode }) {
  const filteredGuests = episode.guests
    ? episode.guests.filter((x) => x.name !== "Matt Hurdle")
    : []
  return (
    <aside className="sidebar">
      <div
        style={{ display: "flex", flexDirection: "column" }}
        sx={{
          mb: 150,
          pr: [10, 0],
          a: { color: "text", textDecoration: "none" },
        }}
      >
        <img
          src={logo}
          alt="CinemaMen Logo"
          style={{ marginBottom: "15px", alignSelf: "center" }}
          width="100"
          height="100"
        />
        {config.anchorUrl && (
          <PodcastProvider
            to={config.anchorUrl}
            style={{ marginBottom: "1em" }}
          >
            <img
              src={anchorImage}
              alt="Anchor.fm logo"
              width="30"
              height="30"
              style={{ marginBottom: "2px" }}
            />
            <div style={{ marginLeft: "3px" }}>Anchor.fm</div>
          </PodcastProvider>
        )}
        {config.spotifyUrl && (
          <PodcastProvider to={config.spotifyUrl}>
            <img src={spotifyImage} alt="Spotify logo" width="90" />
          </PodcastProvider>
        )}
        {config.applePodcastsUrl && (
          <PodcastProvider to={config.applePodcastsUrl}>
            <img src={itunesIcon} alt="Apple Podcasts" />
          </PodcastProvider>
        )}
        {config.googlePodcastsUrl && (
          <PodcastProvider to={config.googlePodcastsUrl}>
            <img src={googleImage} alt="Google Podcasts" />
          </PodcastProvider>
        )}
      </div>

      <div>
        {filteredGuests.length > 0 && (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h5 className="guest">Guests</h5>
            {filteredGuests.map((x) => {
              return (
                <>
                  <Img
                    sx={{
                      borderRadius: 0,
                      width: "100%",
                      maxWidth: 100,
                    }}
                    fluid={x.image.fluid}
                    alt={x.name}
                  />

                  <h4 sx={{ mt: 3, mb: 1 }}>{x.name}</h4>
                </>
              )
            })}
          </div>
        )}
      </div>
    </aside>
  )
}

export default Aside
